import { Container, Box, Typography } from '@material-ui/core'
import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <Box my={30}>
        <Typography component="h2" variant="h2">
          404: Not Found
        </Typography>
        <Typography variant="body1">
          You just hit a route that doesn&#39;t exist... the sadness.
        </Typography>
      </Box>
    </Container>
  </Layout>
)

export default NotFoundPage
